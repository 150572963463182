/**
 * Const with params element
 * Block feature component
 * Display a block of content with an image
 */
/* Import section */
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import "../styles/components/blockfeature.scss"

/* Declaration function */
const Blockfeature = ( { keyFeature, idFeature, titleFeature, descFeature, visualFeature, reverse} ) => {
  return (
    <div key={keyFeature} id={idFeature} className="wrap-maincontent block-feature">
      <div className={`flex-box ${reverse}`}>
        <div className="intro">
          <h3>{titleFeature}</h3>
          <div dangerouslySetInnerHTML={{ __html:descFeature}}/>
        </div>
        <div className="visual-feature">
          <GatsbyImage image={visualFeature} alt={`Hello Health's Australia: ${titleFeature}`} />
        </div>
      </div>
    </div>
  )
}

/* Export function */
export default Blockfeature

