/**
 * Product post Template
 */
/* Import section */
import { graphql } from "gatsby"
import React from "react"
import Bannertestimonial from "../components/bannertestimonial"
import Bannerwithcta from "../components/bannerwithcta"
import Blockfeature from "../components/blockfeature"
import Herotype1 from "../components/herotype1"
import Layout from "../components/layout"
import Navinternal from "../components/navinternal"
import Sectionchecklist from "../components/sectionchecklist"
import Seo from "../components/seo"
import Titledecorative from "../components/titledecorative"
import "../styles/templates/productpost.scss"


/* Declaration function */
const ProductPostTemplate = ({ data }) => {

  //Const data page
  const dataPage = data.wpProduct
  const dataAcf = dataPage.acfPostProduct
  var classPage = dataPage.slug

  //Array for SliderHero
  let arrTextHero = []
  dataAcf.repeaterHeroProduct.map(texthero => (
    arrTextHero.push({textHero: texthero.introHeroProduct})
  ))

  //Array for Internal nav for features
  let arrItemsnavinternal = []
  dataAcf.repeaterFeaturesProduct.map( feature  => (
    arrItemsnavinternal.push({itemNav: feature.titleFeatureProduct, anchorNav:"#feature-product"})
  ))

  //Arry for Checklist with CTA
  let arrItemschecklist = []
  dataAcf.repeaterBenefitsProduct.map( benefit  => (
    arrItemschecklist.push({textChecklist: benefit.itemBenefitProduct})
  ))

  //Data Testimonial
  var extLinkTestimonial = false
  let arrTestimonial = []
  if(dataAcf.ctaLinkTestimonialProduct !== null) {
    const dataTestimonial = dataAcf.linkTestimonialProduct
    arrTestimonial.push({
      author: dataTestimonial[0].acfPostTestimonials.authorTestimonial,
      content: dataTestimonial[0].acfPostTestimonials.contentTestimonial,
      title: dataTestimonial[0].title,
      video: dataTestimonial[0].acfPostTestimonials.videoLinkTestimonial
    })
    var splitUrlCtaTestimonial= dataAcf.ctaLinkTestimonialProduct.split("/")
    var sourceUrlCtaTestimonial = splitUrlCtaTestimonial[0]

    if(sourceUrlCtaTestimonial !== "") {
      extLinkTestimonial = true
    }
  }

  //Const data Options
  const dataOptionsbanner = data.options.acfPageOptions
  var arrOptionsbanner = []
  arrOptionsbanner = dataOptionsbanner.repeaterBannerCta.filter(function(element){
    return element.topicBannerCta === "demo"
  })

  return (
    <Layout>
      <Seo
        title={dataPage.seo.title}
        description={dataPage.seo.metaDesc}
        slickslider= "slick"
      />

      {/* Hero */}
      <section className={`section-hero-product top-section ${classPage}`}>
        <Herotype1 keyHero={dataPage.title} h1title={dataAcf.titleHeroProduct} arrIntro={arrTextHero} colorBar="blue" />
      </section>

      {/* Features Product */}
      <section className="section-features-product">
        <div className="wrap-intro wrap-maincontent">
          <Titledecorative title={dataAcf.titleFeaturesProduct} />
          <div className="flex-box">
            <div className="intro first-body" dangerouslySetInnerHTML={{ __html:dataAcf.textFeaturesProduct}} />
          </div>
        </div>
        <div className="wrap-features">
          <Navinternal arrItemsNav={arrItemsnavinternal} />
          <div className="box-list-features">
            {dataAcf.repeaterFeaturesProduct.map( (feature, index )  => (
              <Blockfeature
                keyFeature={`index-feature-${index}`}
                idFeature={`feature-product-${index}`}
                titleFeature={feature.titleFeatureProduct}
                descFeature={feature.descriptionFeatureProduct}
                visualFeature={feature.visualFeatureProduct.localFile.childImageSharp.gatsbyImageData}
                reverse={(index % 2 === 0) ? "reverse":""}
              />
            ))}
          </div>
        </div>
      </section>

      {/* Benefits Product */}
      <Sectionchecklist
        classSectionChecklist="section-benefits-product"
        titleSectionChecklist={dataAcf.titleBenefitsProduct}
        textSectionChecklist={dataAcf.textBenefitsProduct}
        arrItemschecklist= {arrItemschecklist}
        ctaLabelChecklist={dataAcf.ctaLabelBenefitsProduct}
        ctaLinkChecklist={dataAcf.ctaLinkBenefitsProduct}
        colorChecklist="blue"
        colorThemeSection="blue"
        colorCtaList="white-format"
      />

      {/* Testimonial Product*/}
      {arrTestimonial.length > 0 && (
        <section className="section-testimonial-product">
          <Bannertestimonial
            titleBannerTestimonial={dataAcf.titleTestimonialProduct}
            textBannerTestimonial={dataAcf.textTestimonialProduct}
            ctaLabelBannerTestimonial={dataAcf.ctaLabelTestimonialProduct}
            ctaLinkBannerTestimonial={dataAcf.ctaLinkTestimonialProduct}
            extLinkBannerTestimonial ={extLinkTestimonial}
            arrContentTestimonial={arrTestimonial}
          />
        </section>
      )}

      {/* Banner CTA Product */}
      <Bannerwithcta
        classBanner="section-banner-product"
        titleBanner={arrOptionsbanner[0].titleBannerCta}
        textBanner={arrOptionsbanner[0].textBannerCta}
        ctaLabelBanner={arrOptionsbanner[0].ctaLabelBanner}
        ctaLinkBanner={arrOptionsbanner[0].ctaLinkBanner}
      />
    </Layout>
  )
}

/* Export template informations */
export default ProductPostTemplate

//QUERY GRAPHSQL ON POST
export const query = graphql`
  query($id: Int!) {
    wpProduct(databaseId: {eq: $id}) {
      title
      slug
      acfPostProduct {
        ctaLabelBenefitsProduct
        ctaLabelTestimonialProduct
        ctaLinkBenefitsProduct
        ctaLinkTestimonialProduct
        linkTestimonialProduct {
          ... on WpTestimonial {
            acfPostTestimonials {
              authorTestimonial
              contentTestimonial
              videoLinkTestimonial
            }
            title
          }
        }
        repeaterBenefitsProduct {
          itemBenefitProduct
        }
        repeaterHeroProduct {
          introHeroProduct
        }
        repeaterFeaturesProduct {
          descriptionFeatureProduct
          titleFeatureProduct
          visualFeatureProduct {
            localFile {
              childImageSharp {
                gatsbyImageData (layout: CONSTRAINED, width: 900, aspectRatio: 1.5)
              }
            }
          }
        }
        textTestimonialProduct
        textFeaturesProduct
        textBenefitsProduct
        titleTestimonialProduct
        titleHeroProduct
        titleFeaturesProduct
        titleBenefitsProduct
      }
      seo {
        title
        metaDesc
      }
    }
    options: wpPage(slug: {eq: "options-website"}) {
      acfPageOptions {
        repeaterBannerCta {
          ctaLabelBanner
          ctaLinkBanner
          textBannerCta
          titleBannerCta
          topicBannerCta
        }
      }
    }
  }
`
